<template>
    <div>
        <div class="white">
            <v-carousel hide-delimiters :continuous="true" :cycle="true" :show-arrows="false" height="auto">
                <div style="height: fit-content;" v-for="banner in banners" :key="banner.image_url">
                    <v-carousel-item>
                        <div class="d-flex justify-center align-center">
                            <img class="imageTag rounded-lg" :src="banner.image_url" alt="Home Screen Banner" @click="redirectTo(banner)">
                        </div>
                    </v-carousel-item>
                </div>
            </v-carousel>
        </div>
    </div>
</template>

<script>
import { local } from 'd3-selection';

    export default {
        name: 'bannersComponent',
        props: {
            banners: {
                type: Array,
                requied: true
            }
        },
        methods: {
            redirectTo(banner) {
                if (banner.route_to == 'BOOK_APPOINTMENT') {
                    this.$router.push({
                        name: "newBookingPage"
                    });
                } else if (banner.route_to == 'FEATURE_SPECIALITY') {
                    localStorage.setItem('bookingType', 'featureSpecialityAppointment')
                    this.$router.push({
                        path: '/customer/viewDoctors?type=SpecPromotion&type1=' + banner.titleKey
                    });
                } else if (banner.route_to == 'ALL_CATEGORIES') {
                    this.$router.push({
                        name: "SpecializationsPage",
                    });
                } else if (banner.route_to == 'WALKIN_DOCTOR_LIST') {
                    this.$router.push({
                        path: '/customer/viewDoctors?type=Walkin&type1=Walkin'
                    });
                }
            }
        },
    }
</script>

<style>
    .imageTag{
        width: 100%;
        height: auto;
    }
</style>